.header {
  flex: 0;
  z-index: 10;
  position: relative;
}

.appBarPrimaryNavigation {
  position: static;
  height: 64px;
  background-color: #ffffff;
  padding: 0 48px;
  border-bottom: 1px solid #f3f6f7;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;

  .flex {
    display: flex;
    align-items: center;
    height: 100%;
    width: 40%;
  }

  nav {
    font-family: proxima-nova, sans-serif;
  }

  .toolBar {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .navItem {
    display: flex;
    height: 100%;
    align-items: center;
    color: #0091ea;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border-bottom: 2.5px solid transparent;
    font-family: proxima-nova, Roboto, sans-serif;
    text-decoration: none;

    &.active,
    &:hover {
      border-bottom: 2.5px solid #0091ea;
      // border-bottom-width: calc(100% + 32px);
    }
  }
}

.appBarTopMenu {
  position: static;
  height: 32px;
  background-color: #ffffff;
  padding: 0 48px;
  border-bottom: 1px solid #f3f6f7;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .menuItem {
    font-family: proxima-nova, sans-serif;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666555;
    &:hover {
      cursor: pointer;
    }
  }

  .factoringAccount {
    margin-right: 18px;
  }

  .open {
    font-size: 19px;
    -webkit-animation: spinOpen 0.2s linear;
    -moz-animation: spinOpen 0.2s linear;
    animation: spinOpen 0.2s linear;
    animation-fill-mode: forwards;
  }

  .rightSideHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .closed {
    font-size: 19px;
    -webkit-animation: spinClosed 0.2s linear;
    -moz-animation: spinClosed 0.2s linear;
    animation: spinClosed 0.2s linear;
    animation-fill-mode: forwards;
  }

  @-moz-keyframes spinOpen {
    0% {
      -moz-transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(180deg);
    }
  }

  @-webkit-keyframes spinOpen {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
    }
  }

  @keyframes spinOpen {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
    }
  }

  @-moz-keyframes spinClosed {
    0% {
      -moz-transform: rotate(180deg);
    }
    100% {
      -moz-transform: rotate(0deg);
    }
  }

  @-webkit-keyframes spinClosed {
    0% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
    }
  }

  @keyframes spinClosed {
    0% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
    }
  }
}

.menuRoot {
  top: 35px !important;
  right: 48px;
  left: unset !important;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2) !important;

  .menuItem {
    font-family: proxima-nova, sans-serif;
    text-decoration: none;
    font-size: 14px;
    color: #666666;
    width: 200px;
  }

  li {
    height: 32px;
    &:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    }
    &:focus {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}
